import { Routes } from '@angular/router';
import {
  EmptyPage,
  MobileCheckOutPage,
  redirectWithQueryParams,
  StoreContainerPage,
  StoreDetailPage,
  StoreLimitedLotteryPage,
  StoreLotteryHistoryPage,
  StoreLotteryNotFoundPage,
  StoreLotteryPage,
  StoreNotificationDetailPage,
  StoreNotificationPage,
  StoreSectionPage,
  TabsPageGuard,
} from '@twogate/slashkit';

const storeRoutes: Routes = [
  {
    path: '',
    canActivate: [TabsPageGuard],
    canDeactivate: [TabsPageGuard],
    component: StoreContainerPage,
    children: [
      {
        path: 'top',
        canActivate: [redirectWithQueryParams('/store')],
        component: EmptyPage,
      },
      {
        path: '',
        component: StoreDetailPage,
      },
      {
        path: 'mobile_check_out',
        component: MobileCheckOutPage,
      },
      {
        path: 'category/:sectionSlug',
        component: StoreSectionPage,
      },
      {
        path: 'lottery',
        children: [
          {
            path: '',
            component: StoreLotteryHistoryPage,
          },
          {
            path: ':lotterySlug',
            component: StoreLotteryPage,
          },
        ],
      },
      {
        path: 'limited/:lotterySlug',
        component: StoreLimitedLotteryPage,
      },
      {
        path: 'lottery-not-found',
        component: StoreLotteryNotFoundPage,
      },
      {
        path: 'notification',
        children: [
          {
            path: '',
            component: StoreNotificationPage,
          },
          {
            path: ':notificationId',
            component: StoreNotificationDetailPage,
          },
        ],
      },
    ],
  },
];

export default storeRoutes;
